import { FunctionComponent, VNode, h } from 'preact';
import HDIcon from '@exaring/assets/icons/hd.svg';

import { styled } from '../theme';
import { ProgramChannel } from '../EpgDetails/ProgramChannel';
import { Program, LayoutType } from './types';

const ProgramPagingInfo = styled('p', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '26px',
    height: '18px',
    fontSize: '12px',
    color: '#53525B',
    border: '1.7px solid #53525B',
    fontWeight: '$3',
    borderRadius: '2px',
    padding: '7px 3px',
});

const StyledSection = styled('div', {
    backgroundColor: 'rgba(9, 7, 20, 0.9)',
    backdropFilter: 'blur(6px)',
    position: 'sticky',
    top: '118px',
    zIndex: '3',
    display: 'flex',
    minHeight: '72px',
    alignItems: 'center',
    justifyContent: 'space-between',
    variants: {
        layout: {
            modal: {
                top: '44px',
            },
            player: {
                backgroundColor: 'transparent',
                position: 'fixed',
                paddingRight: '52px',
                top: '109px',
                width: '100%',
            },
            mediathek: {
                width: '100%',
            },
            microSite: {},
            epg: {
                top: '108px',
                width: 'calc(100% + 140px)',
                position: 'fixed',
            },
        },
    },
});

const StyledChannelContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    color: '#53525B',
});

export type ChannelSectionProps = {
    program: Program;
    stationDisplay?: string;
    buttons?: VNode<any>;
    channelWithLogo?: boolean;
    channelNumber?: number;
    layout?: LayoutType;
};

export const ChannelSection: FunctionComponent<ChannelSectionProps> = ({
    program,
    stationDisplay,
    buttons,
    channelNumber,
    layout,
}) => {
    return (
        <StyledSection layout={layout}>
            <StyledChannelContainer>
                <ProgramChannel program={program} stationDisplay={stationDisplay} />
                {!!channelNumber && <ProgramPagingInfo>{channelNumber}</ProgramPagingInfo>}
                <HDIcon />
            </StyledChannelContainer>
            {!!buttons && buttons}
        </StyledSection>
    );
};
