/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, Fragment, FunctionComponent } from 'preact';

import { Globals, styled } from '@exaring/ui/components-styled/theme';
import { isNil } from '@exaring/utils';
import { Button, StyledEditIcon } from '@exaring/ui/components-styled/Button';
import { useEffect, useState, useMemo } from 'preact/hooks';
import { CloudStorageInfo } from '@exaring/ui/components-styled/CloudStorageInfo/CloudStorageInfo';
import { ToggleButton } from '@exaring/ui/components-styled/ToggleButton/ToggleButton';
import { FlexContainer } from '@exaring/ui/components-styled/FlexContainer/FlexContainer';
import { ShowOnlyOnDesktop } from '@exaring/ui/components-styled/ShowOnlyOnDesktop/ShowOnlyOnDesktop';
import { RectangleSkeleton } from '@exaring/ui/components-styled/Skeleton/RectangleSkeleton';
import { RecordingDiscovery } from '@exaring/networking/types/Recording/RecordingDiscovery';

import { Recording } from '@exaring/networking/types/Recording/Recording';
import { Exception } from '@exaring/ui/components-styled/Modal';
import Header from '../components/Header';
import { RouteParams } from '../@types/router';
import { HeaderMenu, PageHeader } from '../components/PageHeadline/PageHeader';
import { TileHero } from '../components/Tile/TileHero/TileHero';
import { Routes, routeTo } from '../routes';
import { RecordingGrid } from '../components/Recording2/RecordingGrid';
import { DiscoveryScroller } from '../components/Recording2/DiscoveryScroller';
import { useRecordings } from '../components/Recording2/hooks/useRecordings';
import {
    TileHeroEmptyContent,
    TileHeroUpgradeContent,
} from '../components/Tile/TileHero/TileHeroEmptyRoom';
import {
    recordingStore,
    notificationsStore,
    useRecordingStore,
    useUserStore,
    useUiStore,
} from '../state/Store';
import {
    FilterOption,
    filter,
    matchAllFilter,
    matchContinueFilter,
    matchSeenFilter,
    matchNewFilter,
    matchScheduledFilter,
} from '../components/Recording2/filter';
import { SortingOption, sorting } from '../components/Recording2/sorting';

import { RecordingDetailsModal } from '../components/Recording2/RecordingDetailsModal/RecordingDetailsModal';
import { useRecordingDetails } from '../components/Recording2/hooks/useRecordingDetails';
import { RecordingFilterSortingMenu } from '../components/Recording2/RecordingFilterSortingMenu';
import { useRecordingSelection } from '../components/Recording2/hooks/useRecordingSelection';
import { didSucceed, RemoteDataStates } from '../state/utils/RemoteData';
import constants from '../constants';
import { WebClientGAEvent, WebClientGA } from '../web-client-ga';
import { RecordingSeriesModal } from '../components/Recording2/RecordingDetailsModal/RecordingSeriesModal';
import { RecordingEditMenu } from '../components/Recording2/RecordingEditMenu';
import { Headline } from '../components/PageHeadline/Headline';
import { RecordingUpsellingModal } from '../components/Recording2/RecordingUpsellingModal';
import { useRecordingContentDiscovery } from '../components/Recording2/hooks/useRecordingContentDiscovery';
import { TileHeroEmptyRoomWrapper } from './TileHeroEmptyRoomWrapper';
import { useGoBack } from '../hooks/useGoBack';
import { trackingFilterValueConverter, trackingSortValueConverter } from '../helper';
import { useMemoData } from '../hooks/useMemoData';

const PAGE_HEADER_HEIGHT = '72px';

const StyledRecordingPage = styled('div', {
    $$containerIndent: `62px`,
    $$gutterWidth: `20px`,
    $$gutterWidthSmall: `10px`,
    $$sectionGutter: `50px`,
    position: 'relative',
    marginTop: Globals.headerHeight,
    width: '100%',
});

const MainContent = styled('div', {
    position: 'relative',
    marginLeft: '$$containerIndent',
    marginRight: '$$containerIndent',
});

interface RecordingPageProps {
    matches: RouteParams;
}

export const RecordingPage: FunctionComponent<RecordingPageProps> = ({
    matches: { path: recordingPath },
}) => {
    const recordingGroup = recordingPath?.match(/^group\/(.*)\/?$/)?.[1];
    const programId = recordingPath?.match(/^program\/(.*)\/?$/)?.[1];
    const recordingId = recordingPath?.match(/^(\w+)$/)?.[1];

    const notifications = notificationsStore();
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const {
        getSelectionState,
        getSelectedRecordings,
        selectRecording,
        toggleRecordingSelection,
        clearRecordingSelection,
        isRecordingSelected,
    } = useRecordingSelection();

    const [activeFilter, setActiveFilter] = useState<FilterOption>(FilterOption.All);
    const [activeSorting, setActiveSorting] = useState<SortingOption>(SortingOption.DateDesc);
    const [stationDisplayName, setStationDisplayName] = useState<string>('');
    const { isFreeUser } = useUserStore();
    const [recordings, unfilteredRecordingsList, recordingFetchState] = useRecordings(
        filter(activeFilter),
        sorting(activeSorting),
    );

    const {
        initRecordingsSummary,
        recordingsSummary,
        showUpsellingModal,
        setShowUpsellingModal,
        recordingGroups,
        getRecordingGroup,
        setDeletedEpisodeIdx,
        setOpenEpisodeIdx,
    } = useRecordingStore();

    const { previousRoute } = useUiStore();

    const goBack = useGoBack();

    const recordingsLoading = recordingFetchState === RemoteDataStates.Loading;

    const { data: contentDiscoveryData } = useRecordingContentDiscovery();
    const discoveryData: RecordingDiscovery | undefined = didSucceed(contentDiscoveryData)
        ? contentDiscoveryData.value
        : undefined;

    const getChannelNameByProgramId = (programIdentifier: string): string | undefined => {
        const currentProgram = discoveryData?.contents.find((item) => {
            const path = new URL(item.url).pathname.split('/');
            const id = path[Math.max(path.length - 1, 0)] || '';

            return id === programIdentifier;
        });

        return currentProgram?.channelDisplay ?? '';
    };

    useEffect(() => {
        if (recordingGroup) {
            getRecordingGroup(Number(recordingGroup));
        }
    }, [recordingGroup, getRecordingGroup]);

    useEffect(() => {
        initRecordingsSummary();
    }, [initRecordingsSummary]);

    useEffect(() => {
        clearRecordingSelection();
    }, [isEditMode]); // Reset selection when edit mode is toggled

    useEffect(() => {
        // Refetch recordings if the user comes from a different route other than the recording page
        if (previousRoute !== undefined && !previousRoute.startsWith(Routes.RECORDING_PAGE)) {
            recordingStore().fetchRecordings();
        }
    }, [previousRoute]);

    const firstRecording = recordings[0];
    const { data } = useRecordingDetails(firstRecording?.id);
    const allRecordingsSelected =
        recordings.length > 0 && recordings.length === getSelectedRecordings()!.length;

    const deleteSelectedRecordings = async (recGroup?: number) => {
        WebClientGA().trackEvent({
            eventName: WebClientGAEvent.Recordings,
            eventDescription: WebClientGAEvent.DeleteSelectedRecordings,
            screenName: 'recordings',
        });

        const selectedRecordingsForDeletion = getSelectionState();

        if (selectedRecordingsForDeletion.length > 0) {
            // Non series recordings;
            const recordingIds = selectedRecordingsForDeletion
                .filter((selection) => selection.wholeGroup === false)
                .map((selection) => selection.recording.id);

            const groupIds = selectedRecordingsForDeletion
                .filter((selection) => selection.wholeGroup === true)
                .map((selection) => selection.recording.recordingGroup as number);

            const recordingSelection =
                groupIds.length > 0 && activeFilter === FilterOption.Scheduled
                    ? 'PLANNED'
                    : 'AVAILABLE';

            const episodesNum =
                recordingIds.length +
                selectedRecordingsForDeletion
                    .filter((selection) => selection.wholeGroup)
                    .reduce(
                        (acc, selection) => acc + (selection.recording.totalEpisodeCount ?? 0),
                        0,
                    );

            await recordingStore().deleteRecordings(
                recordingIds,
                groupIds,
                [recordingSelection],
                episodesNum,
            );

            await recordingStore().fetchRecordings();
            await initRecordingsSummary();

            clearRecordingSelection();

            if (recGroup) {
                await getRecordingGroup(recGroup, undefined, true);
                setOpenEpisodeIdx(undefined);
                routeTo(`${Routes.RECORDING_PAGE_GROUP}/${recGroup}`);
            } else if (window.location.href !== Routes.RECORDING_PAGE) {
                closeDetailsCallback();
            }
        }
        setIsEditMode(false); // reset edit mode after deletion
    };

    const toggleRecordingCallback =
        (recordingIdentifier: string, recordingGroupId?: number) =>
        (event?: MouseEvent | TouchEvent) => {
            event?.stopPropagation();

            const contextRecording = recordings.find(
                (recording) => recording.id === recordingIdentifier,
            );

            if (contextRecording) {
                toggleRecordingSelection(contextRecording, recordingGroupId !== undefined);
            }
        };

    const playMediaCallback = (recordingIdentifier: string, groupIdentifier?: number) => () => {
        const foundRecording = recordings.find((recording) => recording.id === recordingIdentifier);

        if (isEditMode) {
            toggleRecordingCallback(recordingIdentifier, groupIdentifier)();
            return;
        }

        if (!isFreeUser && !groupIdentifier && foundRecording?.locked) {
            notifications.createRecordingStorageUpsellingToast({
                onCancel: () => {
                    WebClientGA().trackEvent({
                        eventName: WebClientGAEvent.Recordings,
                        eventDescription: WebClientGAEvent.DismissRecordingsBlocker,
                        screenName: 'recordings_blocker',
                    });
                },
            });
            return;
        }
        if (foundRecording?.locked) {
            toggleUpsellingModal();
        } else {
            WebClientGA().trackEvent({
                eventName: WebClientGAEvent.PlayerControls,
                eventDescription: WebClientGAEvent.Play,
                programId: foundRecording?.programId,
                programTitle: foundRecording?.title,
                channelName: foundRecording?.stationDisplay,
                screenName: 'player_recordings',
                playerType: 'recording',
            });
            routeTo(`${Routes.RECORDING_PLAYOUT_PAGE}/${recordingIdentifier}`);
        }
    };

    const getNumRecordings = (recording: Recording, isGridRecording?: boolean) => {
        let numRecordings = 1;
        if (recording?.totalEpisodeCount && isGridRecording) {
            numRecordings = recording?.totalEpisodeCount;
        }

        return numRecordings;
    };

    const closeDetailsCallback = () => {
        routeTo(Routes.RECORDING_PAGE);
        setDeletedEpisodeIdx(undefined);
    };

    const recordingGroupRemoteData = recordingGroup && recordingGroups[Number(recordingGroup)];

    const recordingGroupWithoutSeasonFilter = useMemoData(
        recordingGroupRemoteData && didSucceed(recordingGroupRemoteData)
            ? (recordingGroupRemoteData.value as any)
            : [],
        filter(activeFilter),
        sorting(activeSorting),
    );

    const deleteRecordingCallback =
        (
            recordingIdentifier: string,
            recordingGroupId?: number,
            // @ts-expect-error not used variable
            locked?: boolean,
            deleteSeries?: boolean,
            index?: number,
        ) =>
        async (event: MouseEvent | TouchEvent) => {
            event?.stopPropagation();

            let deletedEpisodeIndex = index;

            if (!isNil(index) && recordingGroupId) {
                const idxOfDeletedEpisode = recordingGroupWithoutSeasonFilter.findIndex(
                    (recording: Recording) => recording.id === recordingIdentifier,
                );
                deletedEpisodeIndex = idxOfDeletedEpisode > -1 ? idxOfDeletedEpisode : index;
            }

            const contextRecording =
                recordings.find((recording) => recording.id === recordingIdentifier) ||
                (recordingGroupId &&
                    (recordingGroups[recordingGroupId] as any)?.value.find(
                        (recording: Recording) => recording.id === recordingIdentifier,
                    ));

            const numRecordings = contextRecording
                ? getNumRecordings(contextRecording, deleteSeries)
                : 0;

            if (contextRecording) {
                selectRecording(contextRecording, deleteSeries);

                notifications.confirmDeleteRecordings(numRecordings, {
                    onConfirm: () => {
                        if (numRecordings > 1) {
                            WebClientGA().trackEvent({
                                eventName: WebClientGAEvent.Recordings,
                                eventDescription: WebClientGAEvent.DeleteSelectedRecordings,
                                screenName: 'recordings',
                            });
                        } else {
                            WebClientGA().trackEvent({
                                eventName: WebClientGAEvent.Recordings,
                                eventDescription: WebClientGAEvent.DeleteRecording,
                                screenName: 'recordings',
                            });
                        }
                        deleteSelectedRecordings(recordingGroupId);

                        if (!isNil(deletedEpisodeIndex)) {
                            setDeletedEpisodeIdx(deletedEpisodeIndex);
                        }
                    },
                    onCancel: () => clearRecordingSelection(),
                });
            }
        };

    const toggleUpsellingModal = () => {
        setShowUpsellingModal(!showUpsellingModal);
    };

    const showDeleteModal = () => {
        const numRecordings = getSelectionState().reduce((acc, curr) => {
            return acc + getNumRecordings(curr.recording, curr.wholeGroup);
        }, 0);

        notifications.confirmDeleteRecordings(numRecordings, {
            onConfirm: deleteSelectedRecordings,
        });
    };

    const disableSorting = firstRecording === undefined || isFreeUser || recordings.length === 0;

    const isFilterDisabledMap: Record<FilterOption, boolean> = {
        // If value is true, the filter is disabled. Filter is disabled if there are no recordings matching the filter
        [FilterOption.All]: !unfilteredRecordingsList.find(matchAllFilter),
        [FilterOption.Continue]: !unfilteredRecordingsList.find(matchContinueFilter),
        [FilterOption.Seen]: !unfilteredRecordingsList.find(matchSeenFilter),
        [FilterOption.New]: !unfilteredRecordingsList.find(matchNewFilter),
        [FilterOption.Scheduled]: !unfilteredRecordingsList.find(matchScheduledFilter),
    };

    const hideFiltersAndSorting =
        isFreeUser || Object.values(isFilterDisabledMap).every((value) => value);

    const openDetailsCallback =
        (
            detailsRecordingId: string,
            recordingGroupId?: number,
            locked?: boolean,
            isTileHero?: boolean,
        ) =>
        () => {
            const currentRecording = recordings.find((item) => item.id === detailsRecordingId);

            WebClientGA().trackEvent({
                eventName: WebClientGAEvent.Recordings,
                eventDescription: isTileHero
                    ? WebClientGAEvent.SelectStageRecording
                    : WebClientGAEvent.SelectRecording,
                programId: currentRecording?.programId,
                programTitle: currentRecording?.title,
                channelName: currentRecording?.stationDisplay,
                screenName: 'recordings',
            });
            const recGroupRemoteData = recordingGroupId && recordingGroups[recordingGroupId];
            const recordingGroupValue =
                recGroupRemoteData && didSucceed(recGroupRemoteData)
                    ? recGroupRemoteData.value
                    : undefined;
            const groupHasLockedRecordings = recordingGroupValue?.some(
                (recording) => recording.locked === true,
            );

            if (isEditMode) {
                toggleRecordingCallback(detailsRecordingId, recordingGroupId)();
                return;
            }

            if (isFreeUser && (locked || groupHasLockedRecordings)) {
                toggleUpsellingModal();
                return;
            }

            setStationDisplayName(currentRecording?.stationDisplay || '');

            if (recordingGroupId) {
                routeTo(`${Routes.RECORDING_PAGE_GROUP}/${recordingGroupId}`);
            } else {
                routeTo(`${Routes.RECORDING_PAGE}/${detailsRecordingId}`);
            }
        };

    const stageTile = useMemo(
        () =>
            firstRecording ? (
                activeFilter === FilterOption.All && (
                    <TileHero
                        data={data}
                        hover
                        stationDisplay={firstRecording.stationDisplay}
                        duration={firstRecording.durationSeconds}
                        onPlayContent={playMediaCallback(
                            firstRecording.id,
                            firstRecording.recordingGroup,
                        )}
                        onOpenDetails={openDetailsCallback(
                            firstRecording.id,
                            firstRecording.recordingGroup,
                            firstRecording.locked,
                            true,
                        )}
                        onDelete={deleteRecordingCallback(firstRecording.id)}
                        selectable={isEditMode}
                        onToggleSelection={toggleRecordingCallback(firstRecording.id)}
                        isSelected={isRecordingSelected(firstRecording.id)}
                    />
                )
            ) : (
                <TileHeroEmptyRoomWrapper isFreeUser={isFreeUser}>
                    {isFreeUser ? (
                        <TileHeroUpgradeContent
                            onClick={() => {
                                window.open(
                                    `${constants.WAIPU_CLIENT_BASE_URL}/customer-funnel/payment?product=top_1_abo_2017_11`,
                                    '_blank',
                                    'noreferrer',
                                );
                            }}
                        />
                    ) : (
                        <TileHeroEmptyContent
                            onClick={(e) => {
                                const path = (e?.currentTarget as any)?.title;
                                window.open(path, '_self', 'noreferrer');
                            }}
                        />
                    )}
                </TileHeroEmptyRoomWrapper>
            ),
        [firstRecording, data, isEditMode, isFreeUser, activeFilter, isRecordingSelected],
    );

    return (
        <>
            <Header id="recordings-page" />
            <StyledRecordingPage>
                {recordingFetchState === RemoteDataStates.Success && recordings && (
                    <>
                        <PageHeader
                            css={{
                                justifyContent: 'space-between',
                                height: PAGE_HEADER_HEIGHT,
                                gap: 'unset',
                            }}
                            headlineStubCss={{ height: PAGE_HEADER_HEIGHT }}
                        >
                            {isEditMode ? (
                                <Headline css={{ marginLeft: '0' }}>Aufnahmen verwalten</Headline>
                            ) : (
                                didSucceed(recordingsSummary) && (
                                    <CloudStorageInfo
                                        maxStorage={recordingsSummary.value.maxStorage}
                                        usedStorage={recordingsSummary.value.usedStorage}
                                    />
                                )
                            )}

                            <FlexContainer css={{ flexGrow: 1 }}>
                                {isEditMode ? (
                                    <RecordingEditMenu
                                        css={{ width: '100%' }}
                                        onDelete={showDeleteModal}
                                        disabled={getSelectedRecordings().length === 0}
                                    />
                                ) : (
                                    !hideFiltersAndSorting && (
                                        <RecordingFilterSortingMenu
                                            css={{
                                                width: 'auto',
                                                flexGrow: 1,
                                                '@lg': {
                                                    columnGap: 'unset',
                                                },
                                            }}
                                            activeFilter={activeFilter}
                                            activeSorting={activeSorting}
                                            setActiveFilter={(value) => {
                                                WebClientGA().trackEvent(
                                                    {
                                                        eventName: WebClientGAEvent.Recordings,
                                                        eventDescription:
                                                            WebClientGAEvent.SelectFilter,
                                                        screenName: 'recordings',
                                                    },
                                                    {
                                                        event_details:
                                                            trackingFilterValueConverter(value),
                                                    },
                                                );
                                                setActiveFilter(value);
                                            }}
                                            setActiveSorting={(value) => {
                                                WebClientGA().trackEvent(
                                                    {
                                                        eventName: WebClientGAEvent.Recordings,
                                                        eventDescription:
                                                            WebClientGAEvent.SelectSortOrder,
                                                        screenName: 'recordings',
                                                    },
                                                    {
                                                        event_details:
                                                            trackingSortValueConverter(value),
                                                    },
                                                );
                                                setActiveSorting(value);
                                            }}
                                            disableSorting={disableSorting}
                                            isFilterDisabledMap={isFilterDisabledMap}
                                        />
                                    )
                                )}
                                {!hideFiltersAndSorting && (
                                    <Button
                                        css={{
                                            fontSize: '$2',
                                            height: '36px',
                                        }}
                                        epg
                                        onClick={() => {
                                            WebClientGA().trackEvent({
                                                eventName: WebClientGAEvent.Recordings,
                                                eventDescription: isEditMode
                                                    ? WebClientGAEvent.ExitEditRecordings
                                                    : WebClientGAEvent.EditRecordings,
                                                screenName: 'recordings',
                                            });

                                            setIsEditMode((state) => !state);
                                        }}
                                        active={isEditMode}
                                        disabled={disableSorting}
                                        icon={<StyledEditIcon />}
                                    >
                                        <ShowOnlyOnDesktop>
                                            {isEditMode ? 'Abbrechen' : 'Bearbeiten'}
                                        </ShowOnlyOnDesktop>
                                    </Button>
                                )}
                            </FlexContainer>
                        </PageHeader>
                        <PageHeader
                            title="Aufnahmen"
                            css={{ justifyContent: 'left', position: 'relative', zIndex: 1 }}
                            slim
                        >
                            {isEditMode && (
                                <HeaderMenu>
                                    <ToggleButton
                                        css={{
                                            height: '36px',
                                            padding: '15px 20px',
                                        }}
                                        onClick={() => {
                                            if (allRecordingsSelected) {
                                                WebClientGA().trackEvent({
                                                    eventName: WebClientGAEvent.Recordings,
                                                    eventDescription:
                                                        WebClientGAEvent.UnmarkAllRecordings,
                                                    screenName: 'recordings',
                                                });
                                                clearRecordingSelection();
                                            } else {
                                                WebClientGA().trackEvent({
                                                    eventName: WebClientGAEvent.Recordings,
                                                    eventDescription:
                                                        WebClientGAEvent.MarkAllRecordings,
                                                    screenName: 'recordings',
                                                });
                                                selectRecording(recordings);
                                            }
                                        }}
                                        selected={allRecordingsSelected}
                                    >
                                        {allRecordingsSelected ? 'Alle abwählen' : 'Alle auswählen'}
                                    </ToggleButton>
                                </HeaderMenu>
                            )}
                        </PageHeader>
                        <MainContent>
                            {recordingsLoading ? (
                                <RectangleSkeleton
                                    css={{
                                        borderRadius: '$1',
                                        width: 'calc(100vw - $containerIndent)',
                                        minHeight: '200px',
                                        aspectRatio: '3.56 / 1',
                                    }}
                                />
                            ) : (
                                stageTile
                            )}

                            {recordings.length > 0 && (
                                <RecordingGrid
                                    recordings={recordings}
                                    isContentSelected={isRecordingSelected}
                                    onSelectContent={toggleRecordingCallback}
                                    onPlayContent={playMediaCallback}
                                    onDeleteContent={deleteRecordingCallback}
                                    onOpenDetails={openDetailsCallback}
                                    editMode={isEditMode}
                                    loading={recordingsLoading}
                                />
                            )}

                            <DiscoveryScroller />
                        </MainContent>
                        {recordingGroup && (
                            <RecordingSeriesModal
                                onDeleteContent={deleteRecordingCallback}
                                onClose={closeDetailsCallback}
                                recordingGroup={parseInt(recordingGroup, 10)}
                                fullscreen
                            />
                        )}
                        {(programId || recordingId) && (
                            <RecordingDetailsModal
                                onDeleteRecording={deleteRecordingCallback}
                                onPlayContent={playMediaCallback}
                                onClose={closeDetailsCallback}
                                stationDisplay={
                                    programId
                                        ? getChannelNameByProgramId(programId)
                                        : stationDisplayName
                                }
                                recordingId={recordingId}
                                programId={programId}
                                fullscreen
                            />
                        )}

                        {showUpsellingModal && (
                            <RecordingUpsellingModal toggleUpsellingModal={toggleUpsellingModal} />
                        )}
                    </>
                )}
                {recordingFetchState === RemoteDataStates.Failed && (
                    <Exception
                        title="Ihre Aufnahmen konnten nicht geladen werden."
                        subtitle="Leider konnten Ihre Aufnahmen nicht geladen werden. Bitte laden Sie die Seite
                                        erneut oder versuchen Sie es zu einem späteren Zeitpunkt nochmal."
                        onClick={goBack}
                        withReload
                    />
                )}
            </StyledRecordingPage>
        </>
    );
};
