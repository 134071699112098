import { CSS } from '../theme';
import {
    PrimaryStyles,
    PrimaryDisabledStyles,
    SecondaryStyles,
    SecondaryDisabledStyles,
    DarkStyles,
    DarkDisabledStyles,
    EpgDisabledStyles,
    EpgActiveStyles,
} from './types';

// button style reference https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/5bbdfa3744616109dab66783

const buttonInteractionStylesPrimary = {
    borderColor: '$darkBlue',
    backgroundColor: '$darkBlue',
    outline: 'none',
};

export const primaryStyles: PrimaryStyles = {
    margin: '0',
    height: 'auto',
    width: 'auto',
    padding: '6px 12px',
    borderRadius: '4px',
    boxShadow: '0 1px 1px 0 rgba(17, 61, 114, 0.8)',
    fontFamily: '$primary',
    fontWeight: '$1',
    color: '$white',
    lineHeight: '1.5em',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    backgroundColor: '$babyBlue',
    cursor: 'pointer',

    '&:focus': buttonInteractionStylesPrimary,
    '&:hover': buttonInteractionStylesPrimary,
    '&:active': {
        ...buttonInteractionStylesPrimary,
        boxShadow: 'none',
    },
};

const buttonInactiveStylesPrimary: CSS = {
    cursor: 'default',
    color: '$white',
    borderColor: '$babyBlue',
    backgroundColor: '$babyBlue',
    opacity: 0.5,
};

export const primaryDisabledStyles: PrimaryDisabledStyles = {
    ...buttonInactiveStylesPrimary,
    '&:hover': buttonInactiveStylesPrimary,
    '&:focus': buttonInactiveStylesPrimary,
    '&:active': buttonInactiveStylesPrimary,
};

// secondary not in use yet
export const secondaryStyles: SecondaryStyles = {};
export const secondaryDisabledStyles: SecondaryDisabledStyles = {};

// https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/622b48c71852b212f15679fd
const buttonInteractionStylesDark: CSS = {
    backgroundColor: '$white',
    color: '#0090d0',
};

export const darkStyles: DarkStyles = {
    ...buttonInteractionStylesDark,
    borderColor: '#0090d0',

    '&:focus': buttonInteractionStylesDark,
    '&:hover': buttonInteractionStylesDark,
    '&:active': buttonInteractionStylesDark,
};

export const darkDisabledStyles: DarkDisabledStyles = {
    ...buttonInteractionStylesDark,
    '&:hover': buttonInteractionStylesDark,
    '&:focus': buttonInteractionStylesDark,
    '&:active': buttonInteractionStylesDark,
};

export const epgStyles: PrimaryDisabledStyles = {
    backgroundColor: '$dark',
    boxShadow: 'none',
    borderRadius: 5,
    width: 'auto',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&:hover': {
        backgroundColor: '$charcoalGrey',
    },
};

const buttonInteractionStylesEpg: CSS = {
    opacity: '.2', // 🗿
    background: '$dark',
    pointerEvents: 'none',
};

export const epgDisabledStyles: EpgDisabledStyles = {
    ...buttonInteractionStylesEpg,
    '&:hover': buttonInteractionStylesEpg,
    '&:focus': buttonInteractionStylesEpg,
    '&:active': buttonInteractionStylesEpg,
};
export const epgActiveStyles: EpgActiveStyles = {
    color: '$white',
    background: '$darkBlue',
};
