import { h, FunctionComponent } from 'preact';
import { styled } from '@stitches/react';
import { Recording } from '@exaring/networking/types/Recording/Recording';
import { RecordingListItem } from './RecordingListItem';
import type { ItemContextCallback } from './RecordingGrid';

const StyledList = styled('ol', {
    overflowY: 'visible',
});
export const RecordingList: FunctionComponent<{
    recordings: Recording[];
    isContentSelected: (id: string) => boolean;
    onSelectContent: ItemContextCallback;
    onDeleteContent?: ItemContextCallback;
    onPlayContent: (recordingId: string) => () => void;
    onOpenDetails: (recordingId: string) => () => void;
    editMode?: boolean;
    defaultStation?: string;
}> = ({
    recordings,
    onPlayContent,
    editMode,
    onSelectContent,
    onDeleteContent,
    onOpenDetails,
    isContentSelected,
    defaultStation,
}) => {
    return (
        <StyledList>
            {recordings?.map((recording: Recording, index: number) => (
                <RecordingListItem
                    index={index}
                    onDeleteContent={onDeleteContent}
                    onPlayContent={onPlayContent(recording.id)}
                    recording={recording}
                    selectable={editMode}
                    isContentSelected={isContentSelected}
                    onSelectContent={onSelectContent}
                    defaultStation={defaultStation}
                    onOpenDetails={onOpenDetails}
                />
            ))}
        </StyledList>
    );
};
