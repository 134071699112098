/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import baseConstants from '@exaring/ui/constants';
import { getBaseUrl } from '@exaring/utils';

/* global __CONFIG__, __ENVIRONMENT__, __RELEASE__, __GIT_HASH__, __SENTRY_URL__, __TENANT__ */

const base = getBaseUrl(window.location.href);

const clientConstants = {
    CLIENT_ID: 'waipu.web-player',
    CHANGE_EMAIL_URL: `https://customer-self-care.${base}/ui/account/email_doi`,
    GA_ID: 'GTM-P73RK4',
    ENV: __ENVIRONMENT__,
    RELEASE: __RELEASE__,
    GIT_HASH: __GIT_HASH__,
    SENTRY_URL: __SENTRY_URL__,
    TENANT: __TENANT__,
    WAIPU_BASE_URL: `https://www.${base}`,
    WAIPU_CLIENT_BASE_URL: `https://client.${base}`,
    PLAYER_NAME: 'Shaka',

    // oauth2+pkce
    DEVICE_CAPABILITIES_TOKEN_REFRESH_INTERVAL: 60e3, // ms
    AUTH_REFRESH_THRESHOLD: 60, // sec
    AUTH_REDIRECT_URL: __CONFIG__.AUTH_REDIRECT_URL,

    STREAM_TTL: 4 * 60 * 60 + 55 * 60, // sec. (4:55 h in sec.)
    STILL_WATCHING_INTERVAL: 60e3, // ms

    // Internal / com constants
    ZAPPING_PLAYOUT_EPG_DATA_UPDATE_INTERVAL: 1e3, // ms
    OSD_DEFAULT_VOLUME: 50, // %
    OSD_VOLUME_SHIFT_STEP: 5, // %
    OSD_VOLUME_AUTO_HIDE_TIMEOUT: 1500, // ms
    OSD_PLAYER_PROGRESS_UPDATE_INTERVAL: 1e3, // ms
    OSD_HIDE_TIMEOUT: 4e3, // ms
    OSD_CLICK_ACTION_TIMEOUT: 250, // ms
    QUICKZAPPER_HIDE_TIMEOUT: 7000, // ms
    PLAYER_PLAYOUT_THROTTLE: 1e3, // ms
    EPG_REFRESH_INTERVAL: 1e3, // ms
    EPG_TIMEMACHINE_TIMESPAN: 7, // days
    RECORDING_REFRESH_TIMEOUT: 1e4, // ms
    SEARCH_RESULT_LIMIT: 50, // it's actually the max limit of what the service is operating
    RECORDING_NET_MIN: 60, // sec
    PLAYER_HEARTBEAT_INTERVAL: 1e3, // ms
    PLAYER_COOLDOWN_TIMEOUT: 3e4, // ms
    CHANNELLIST_AUTOSHOW_DELAY: 1e3, // ms
    CHANNELLIST_AUTOSHOW_DISPLAYTIME: 5e3, // ms
    THUMBNAIL_WIDTH: 640,
    THUMBNAIL_HEIGHT: 360,
    PREVIEW_THUMBNAIL_SCALE_FACTOR: 0.375,
    PREVIEW_THUMBNAIL_PADDING: 40, // px
    NOTIFICATIONS_AUTO_DISSMISS_TIME: 12000,
    RECORDING_ONAIR_START_OFFSET: -15 * 60, // minus 15 minutes
    TIMESHIFT_EXIT_OVERLAY_DISPLAY_TIME: 7e3, // ms
    TIMESHIFT_MIN_LIVE_OFFSET: 40, // sec.
    TIMESHIFT_MAX_LIVE_OFFSET: 150 * 60, // sec.
    SEEK_TIME_FRAME: 30_000, // ms seek time frame, see https://exaring.atlassian.net/browse/TVFUS-40850
    LOW_RECORDING_STORAGE_BOUNDARY: 5 * 3600, // sec.

    // DRM licencing
    LS_WIDEVINE: __CONFIG__.LS_WIDEVINE,
    LS_FAIRPLAY: __CONFIG__.LS_FAIRPLAY,
    LS_FAIRPLAY_CERT: __CONFIG__.LS_FAIRPLAY_CERT,

    // External links
    NO_PACKAGE_UPSELLING_URL: __CONFIG__.NO_PACKAGE_UPSELLING_URL,
    RELOGIN_URL: __CONFIG__.RELOGIN_URL,
    FREE_USER_UPSELLING_URL: __CONFIG__.FREE_USER_UPSELLING_URL,
    APPLE_APP_URL: __CONFIG__.APPLE_APP_URL,
    ANDROID_APP_URL: __CONFIG__.ANDROID_APP_URL,
    CSC_ACCOUNTING_URL: `https://customer-self-care.${base}/ui/my_invoices`,
    STREAM_LOGGING_ENV_KEY: __CONFIG__.STREAM_LOGGING_ENV_KEY,

    CSC_AGE_VERIFICATION_URL: `https://customer-self-care.${base}/ui/fsk`,
    HELPDESK_URL: __CONFIG__.HELPDESK_URL,
    HELPDESK_NETWORK_URL: __CONFIG__.HELPDESK_NETWORK_URL,
    APP_UNIVERSAL_URL: __CONFIG__.APP_UNIVERSAL_URL,

    SHAKA_MUX_SRC: 'https://src.litix.io/shakaplayer/4/shakaplayer-mux.js',
    SHAKA_MUX_FUNC_NAME: 'initShakaPlayerMux',
    // overwrites set Constants by @exaring/ui/constants
    WEB_CLIENT_BASE_URL: `https://${__CONFIG__.SUBDOMAIN}.${base}`,
    FUNNEL_URL: `https://client.${base}/customer-funnel/payment?product=top_1_abo_2017_11`,
    // CSC_HOME_URL: __CONFIG__.CSC_HOME_URL,
    // LOGOUT_URL: __CONFIG__.LOGOUT_URL,

    // Push Notifications
    PUSH_NOTIFICATIONS_FIELD_ID: __CONFIG__.PUSH_NOTIFICATIONS_FIELD_ID,
    PUSH_NOTIFICATIONS_DOMAIN_CODE: __CONFIG__.PUSH_NOTIFICATIONS_DOMAIN_CODE,
    PUSH_NOTIFICATIONS_VAPID_KEY: __CONFIG__.PUSH_NOTIFICATIONS_VAPID_KEY,
    WEB_COMMONS_URL: __WEB_COMMONS_URL__,
} as const;

const constants = { ...baseConstants, ...clientConstants };

const ff = __CONFIG__.featureFlags || {};

const FeatureFlags = {
    enableWaiputhek: !!ff.WAIPUTHEK,
    enableStreamLogging: !!ff.STREAM_LOGGING,
    enablePushNofications:
        !!ff.PUSH_NOTIFICATIONS &&
        !!constants.PUSH_NOTIFICATIONS_DOMAIN_CODE &&
        !!constants.PUSH_NOTIFICATIONS_VAPID_KEY,
};

export { FeatureFlags };
export default constants;
