/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { TenantConstants } from './types/TenantConstants';

// NOTE as this code is injected before compilation this resolves in a false positive
// eslint-disable-next-line import/no-unused-modules
export const tenantConstants: TenantConstants = {
    WAIPUTHEK_BASE_PATH: '/mediathek',
} as const;
