/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

/**
 * Decodes base64url to base64.
 * @param {string} str base64url string
 * @returns {string} base64 with decoded url special characters
 */
export function base64UrlDecode(str) {
    return str.replace(/-/g, '+').replace(/_/g, '/');
}

/**
 * Decodes base64 to string.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/DOMString
 * @param {string} str string
 * @returns {string}
 */
export function base64DecodeUnicode(str) {
    return window.decodeURIComponent(
        atob(str)
            .split('')
            .map((c) => {
                // iterate over all characters
                // bytestream -> percent-encoding/url-encoding -> decode url-encoding
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`; //
            })
            .join(''),
    );
}

/**
 * Decodes base64 or base64url encoding and parse to js object
 * @param {string<base64, base64url>} str
 * @param {boolean} urlDecode flag to auto decode base64url before decoding base64 string
 * @returns {object}
 */
export function base64toObject(str, urlDecode = true) {
    try {
        let s = str;

        if (urlDecode) {
            s = base64UrlDecode(window.decodeURIComponent(s));
        }

        return JSON.parse(base64DecodeUnicode(s));
    } catch (e) {
        return {};
    }
}

/**
 * Decodes jwt token to object
 * @param {string<base64url>} str
 * @returns {object}
 */
export function decode(str) {
    return base64toObject(str.split('.')[1], true);
}

/**
 * Encodes a JavaScript object to base64
 * @param {object} obj - The object to encode
 * @returns {string} - Base64 encoded string
 */
export function objectToBase64(obj) {
    try {
        const jsonString = JSON.stringify(obj);

        const base64 = btoa(unescape(encodeURIComponent(jsonString)));

        return base64;
    } catch (e) {
        console.error('Error encoding object to base64:', e);
        return '';
    }
}
