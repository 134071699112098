import { h, FunctionComponent } from 'preact';
import { CSS } from '@stitches/react';
import O2TelefonicaLogo from '@exaring/assets/images/o2/logo-o2-telefonica.svg';
import { Links } from '../../components/footer-o2';
import { StyledLink } from './StyledLink';
import { StyledParagraph } from './StyledParagraph';
import { StyledContainer } from './StyledContainer';

const LegalLinks = [
    { url: Links.IMPRINT_URL, label: 'Impressum' },
    { url: Links.TERMS_AND_CONDITIONS_URL, label: 'AGB' },
    { url: Links.DATA_PRIVACY_STATEMENT_URL, label: 'Datenschutz' },
] as const;

export const MiniFooter: FunctionComponent<{ css?: CSS; className?: string }> = ({
    css,
    className,
}) => {
    return (
        <StyledContainer css={css} className={className}>
            <StyledParagraph>
                {LegalLinks.map(({ url, label }) => (
                    <StyledLink href={url}>{label}</StyledLink>
                ))}
            </StyledParagraph>
            <StyledParagraph>&copy; Telefónica Germany GmbH & Co. OHG</StyledParagraph>
            <O2TelefonicaLogo disableBoundingReset />
        </StyledContainer>
    );
};
