import { ColorsType, FontType } from './types';

export const Fonts: FontType = {
    primary: 'FrutigerLTStd',
    secondary: 'Poppins',
} as const;

export const Colors: ColorsType = {
    black: '#000000',
    // https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/622b48d4b231eb0770aadacf
    nearBlack: '#0c0f23',
    greyPurple: '#171521',
    charade: '#282632',
    blackPurple: '#0b0a0f',
    // https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/622b48d2578b37072f5859a7
    dark: '#1d1f31',
    white: '#ffffff',
    // white20: fade(white, 20%)
    verylightgray: '#f8f8f8',
    lightgray: '#ececec',
    alto: '#dddddd',
    gray: '#aaaaaa',
    dustyGrey: '#9b9b9b',
    // spanish-gray, waiputhekModulGenreChannelEtc
    mediumGray: '#96959c',
    // granite-grey
    darkgray: '#666666',
    greyishBrown: '#444444',
    // 'greyishBrown50':    fade(greyish-brown, 50%),
    charcoalGrey: '#34323e',
    bluegray: '#cad7de',
    babyBlue: '#65b4e4',
    // brilliantAzureBlue
    bluish: '#3293fc',
    // primaryLightBlue
    blue: '#2789fc',
    // https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/622b48c738eab706e0bff761
    lightBlue: '#41b6e6',
    // 'blue'-50:             fade(blue, 50%),
    logoBlue: '#2468b2',
    // absolute-zero
    darkBlue: '#005896',
    lightNavy: '#113d71',
    darkOrange: '#ff8800',
    gold: '#f3d200',
    // razzmicBerry
    purple: '#a05094',
    burgundy: '#713969',
    // signalRed
    red: '#ff0000',
    pink: '#f65c7c',
    red20: '#ffcccc', // notification (band) error color
    green: '#2fc091',
    // primaryTurquoiseGreen, changed for `EditButton`
    // https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/622b48c71852b212f15679fd
    aquamarine: '#0090d0',
    islamicGreen: '#33bd00',

    spaceCadet: '#19224f',
    brightNavyBlue: '#1786e1',
    spanishGreen: '#159655',
    fluorescentBlue: '#2bfffd',
    netflixRed: '#e50914',
    debianRed: '#e00340',
    melancholicStone: '#586370',

    // EPG 2.0 TODO: change color values for o2
    anthracite: '#282631',
    steelGrey: '#181722',
    cinder: '#181622',
    narwhalGrey: '#0d0b16',
    inkBlack: '#22202a',
    dreamlessSleep: '#110f1a',
    beautifulDarkness: '#6c6b72',
    ebony: '#35333c',
    tuna: '#46454d',

    // https://app.zeplin.io/project/5a27efb37131e85d78e59452/screen/5bbdfa3744616109dab66783 (see toasts)
    toastInfoBackground: '#fff',
    toastInfoText: '#192630',
    toastSuccessBackground: '#74cf9a',
    toastSuccessText: '#000066',
    toastErrorBackground: '#f65d7c',
    toastErrorText: '#fff',
} as const;
