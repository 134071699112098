export const initialisePushNotifications = (constants: any) => {
    const script = document.createElement('script');
    script.setAttribute(
        'src',
        'https://client-version.cf.emarsys.net/web-emarsys-sdk-v4/latest/web-emarsys-sdk.js',
    );
    document.body.appendChild(script);
    script.onload = () => {
        const innerScript = document.createElement('script');
        innerScript.textContent = `
        var WebEmarsysSdk = WebEmarsysSdk || [];
        WebEmarsysSdk.push([
            'init',
            {
                applicationCode: '${constants.PUSH_NOTIFICATIONS_DOMAIN_CODE}',
                enableMacSafariVapid: true,
                defaultNotificationTitle: 'waipu.tv - Fernsehen wie noch nie.',
                defaultNotificationIcon:
                    '${constants.WEB_COMMONS_URL}/flavors/${constants.TENANT}/assets/favicon/favicon.png',
                autoSubscribe: false,
                serviceWorker: {
                    url: 'ui/serviceWorkers/serviceWorker.js',
                    applicationServerPublicKey: '${constants.PUSH_NOTIFICATIONS_VAPID_KEY}',
                },
            },
        ]);
      `;
        document.body.appendChild(innerScript);
    };
};

export const subscribeToPushNotifications = (userHandle: string, emarsysFieldId: number) => {
    WebEmarsysSdk.subscribe().then(() => {
        WebEmarsysSdk.login({
            fieldId: emarsysFieldId,
            fieldValue: userHandle,
        });
    });
};
