import { h, FunctionComponent, ComponentChildren } from 'preact';
import { assetUrl } from '../helper';
import { TileHeroEmptyRoom } from '../components/Tile/TileHero/TileHeroEmptyRoom';

interface TileHeroEmptyRoomWrapperProps {
    children: ComponentChildren;
    isFreeUser: boolean;
}

export const TileHeroEmptyRoomWrapper: FunctionComponent<TileHeroEmptyRoomWrapperProps> = ({
    isFreeUser,
    children,
}) => {
    return (
        <TileHeroEmptyRoom
            imgUrl={assetUrl('recordings-empty-room-o2.jpg')}
            isUpgrade={isFreeUser}
            isEmpty={!isFreeUser}
        >
            {children}
        </TileHeroEmptyRoom>
    );
};
