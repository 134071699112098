import { h } from 'preact';
import RecordingStartIcon from '@exaring/assets/icons/recording-start-o2.svg';
import RecordingStopIcon from '@exaring/assets/icons/recording-stop-o2.svg';
import RecordingDeleteIcon from '@exaring/assets/icons/recording-delete.svg';

import { LoadingSpinnerIcon } from '../LoadingSpinner';
import { Colors } from '../theme';

export const RECORD_ICON_MAP = {
    start: <RecordingStartIcon />,
    stop: <RecordingStopIcon color={Colors.pink} />,
    delete: <RecordingDeleteIcon />,
    spinner: <LoadingSpinnerIcon width={0} height={0} secondary />,
} as const;
