/*
 *  Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent, Ref } from 'preact';
import { useMemo } from 'preact/hooks';
import { Typography } from '@exaring/ui/components-styled/Typography';
import { CSS, styled } from '@exaring/ui/components-styled/theme';
import { GridContainer, GridItem } from '@exaring/ui/components-styled/Grid';
import { VideoTileImageContainer } from '@exaring/ui/components-styled/Tile/parts/VideoTileImageContainer';
import { StyledImageOverlay } from '@exaring/ui/components-styled/Tile/parts/StyledImageOverlay';
import { useHover } from '@exaring/ui/hooks/useHover';

import { StyledMetaSpan } from '@exaring/ui/components-styled/Tile/parts/StyledMetaSpan';
import { diffInSeconds, formatSecondsDuration, date, formatDateTime } from '@exaring/utils/date';

import { ImageWithFallback } from '@exaring/ui/components-styled/Tile/parts/ImageWithFallback';
import type { Recording } from '@exaring/networking/types/Recording/Recording';
import { isScheduled } from '@exaring/utils/data/recording';
import { CircleIcon, TrashcanIcon } from '@exaring/ui/components-styled/Tile/parts/StyledMenu';
import { ProgramProgressBar } from '@exaring/ui/components-styled/ProgramProgressBar/ProgramProgressBar';
import { FlexContainer } from '@exaring/ui/components-styled/FlexContainer/FlexContainer';
import { GridContentContainer, TextWrapper, Title } from '../Tile/TileHero/TileHeroShared';
import { RecordingStatusBadge } from '../Tile/TileHero/TileHero';
import { assetUrl } from '../../helper';
import type { ItemContextCallback } from './RecordingGrid';
import { useRecordingDetails } from './hooks/useRecordingDetails';
import { didSucceed } from '../../state/utils/RemoteData';
import { useRecordingStore } from '../../state/Store';

const Image = styled(ImageWithFallback, {
    width: '100%',
    minHeight: '111px',
    objectFit: 'cover',
    aspectRatio: 16 / 9,
});

const StyledRecordingListItem = styled('li', {
    marginBottom: '$$gutterWidth',
    position: 'relative',
    width: '100%',
    height: '111px',
    borderRadius: '$1',
    overflow: 'hidden',

    [`&:hover ${GridContentContainer}.parent-hover`]: {
        backgroundColor: '$charcoalGrey',
        backgroundImage: 'none',
        cursor: 'pointer',
    },
});

const HeroMetaSpan = styled(StyledMetaSpan, {
    marginTop: '5px',
});

const StyledVideoTileImageContainer = styled(VideoTileImageContainer, {
    height: '100%',
    width: '100%',

    [`&:hover ${StyledImageOverlay}`]: {
        cursor: 'pointer',
        backgroundColor: 'inherit',
    },
});

const RecordingDesc = styled(Typography, {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    font: 'OpenSans-Light, OpenSans',
    display: 'block',
    fontSize: '$8',
    marginTop: '10px',
    marginBottom: '7px',

    '@md': {
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
    },
});

const SeasonEpisodesCountBadge = styled('div', {
    display: 'flex',
    fontSize: '10px',
    font: 'OpenSans-SemiBold, OpenSans',
    alignItems: 'center',
    borderRadius: '$5',
    padding: '3px 10px',
    marginTop: '5px',
    minWidth: '51px',
    height: '16px',
    textTransform: 'uppercase',
    background: '$melancholicStone',
    color: '$white',
});

const PreviewImageContainer = styled('div', {
    position: 'relative',
    height: '100%',
});

const StyledWrapper = styled('div', {
    overflow: 'hidden',
    flexGrow: 1,
});

const TrashIconWrapper = styled('div', {
    alignSelf: 'center',

    display: 'none',
});

const ListItemTextWrapper = styled(TextWrapper, {
    display: 'flex',
    flexGrow: 1,

    '& svg': {
        display: 'block',
    },

    variants: {
        selectable: {
            true: {},
            false: {
                [`&:hover ${TrashIconWrapper}`]: {
                    display: 'block',
                },
            },
        },
    },
});

export const RecordingListItem: FunctionComponent<{
    recording: Recording;
    index: number;
    isLoading?: boolean;
    isContentSelected: (id: string) => boolean;
    onSelectContent: ItemContextCallback;
    onDeleteContent?: ItemContextCallback;
    onPlayContent?: () => void;
    onOpenDetails?: (recordingId: string) => () => void;
    selectable?: boolean;
    css?: CSS;
    defaultStation?: string;
}> = ({
    recording,
    isLoading = false,
    index,
    onPlayContent,
    onOpenDetails,
    selectable = false,
    isContentSelected,
    onSelectContent,
    onDeleteContent,
    css,
}) => {
    const { setOpenEpisodeIdx } = useRecordingStore();
    const [hoverRef, isHovered] = useHover();
    const { data } = useRecordingDetails(recording.id);
    const recordingDetails = didSucceed(data) ? data.value : undefined;

    let imgSrc = '';
    try {
        const url = recording.previewImage;

        if (url) {
            const imgUrl = new URL(url);
            imgSrc = imgUrl.href;
        }
    } catch (_) {
        // if url is malformed we do not track an error...
    }

    const isSelected = useMemo(
        () => isContentSelected(recording.id),
        [recording.id, isContentSelected],
    );

    const isLocked = recording?.locked ?? false;

    const durationInSec = diffInSeconds(
        recording?.recordingStartTime,
        date(recording.recordingStartTime).add(recording.durationSeconds, 's'),
    );

    const handleTileClick = (event: unknown) => {
        if (selectable) {
            onSelectContent?.(recording.id)(event as MouseEvent);
        } else {
            setOpenEpisodeIdx(index);
            onOpenDetails?.(recording.id)();
        }
    };

    const handleVideoTileClick = (event: MouseEvent) =>
        selectable ? onSelectContent(recording.id)(event) : onPlayContent?.();

    return (
        <StyledRecordingListItem css={{ columnGap: '0', ...css }} id={recording.id}>
            <GridContentContainer
                className="parent-hover"
                isLoading={isLoading}
                onClick={handleTileClick}
                isMediaHovered={isHovered && !isScheduled(recording.status)}
                detailsDisabled={!onOpenDetails}
                solidBackground
                css={{ gap: '0', paddingLeft: '207px' }}
            >
                <FlexContainer css={{ width: '100%' }}>
                    <GridContainer
                        justifyContent="space-between"
                        alignItems="center"
                        css={{
                            width: '100%',
                            height: '100%',
                            padding: '30px 20px',
                            alignContent: 'center',
                            flexWrap: 'nowrap',
                        }}
                    >
                        {!isLoading && (
                            <ListItemTextWrapper selectable={selectable}>
                                <StyledWrapper>
                                    <Title
                                        css={{
                                            fontFamily: 'OpenSans-SemiBold, Open Sans',
                                            maxWidth: '404px',
                                            fontSize: '$8',
                                            marginBottom: '0',
                                            '@sm': {
                                                maxWidth: '500px',
                                            },
                                            '@md': {
                                                maxWidth: '540px',
                                            },
                                            '@lg': {
                                                fontSize: 'inherit',
                                            },
                                        }}
                                    >
                                        {recording.episodeTitle}
                                    </Title>
                                    {(recording.season || recording.episode) && (
                                        <GridItem css={{ display: 'flex' }}>
                                            <SeasonEpisodesCountBadge>
                                                {`${
                                                    recording.season ? `S${recording.season}` : ''
                                                } ${
                                                    recording.episode ? `E${recording.episode}` : ''
                                                }`}
                                            </SeasonEpisodesCountBadge>
                                        </GridItem>
                                    )}
                                    <HeroMetaSpan css={{ color: '$lightBlue' }}>
                                        {`${formatDateTime(
                                            recording.epgStartTime,
                                            !!recording.epgStartTime && !!durationInSec,
                                        )} ${formatSecondsDuration(durationInSec)}`}
                                    </HeroMetaSpan>
                                    <RecordingDesc variant="body2">
                                        {recordingDetails?.programDetails.textContent.descShort ||
                                            recordingDetails?.programDetails.textContent.descLong}
                                    </RecordingDesc>
                                </StyledWrapper>
                                <TrashIconWrapper>
                                    <TrashcanIcon
                                        iconSize="medium"
                                        onClick={onDeleteContent?.(
                                            recording.id,
                                            recording.recordingGroup,
                                            recording.locked,
                                            false,
                                            index,
                                        )}
                                        hover
                                    />
                                </TrashIconWrapper>
                            </ListItemTextWrapper>
                        )}
                        {selectable && (
                            <CircleIcon
                                css={{ cursor: 'pointer', flexShrink: 0 }}
                                onClick={onSelectContent?.(recording.id)}
                                checked={isSelected}
                            />
                        )}
                    </GridContainer>
                </FlexContainer>
            </GridContentContainer>
            <GridContentContainer noGradientBackground css={{ width: '207px' }}>
                <FlexContainer css={{ height: '100%' }}>
                    <PreviewImageContainer>
                        <Image
                            src={imgSrc}
                            srcFallback={assetUrl('background-waipu-gradient.png')}
                        />
                        {recording.positionPercentage > 0 && (
                            <ProgramProgressBar
                                progress={recording.positionPercentage}
                                noBorderRadius
                            />
                        )}
                    </PreviewImageContainer>
                </FlexContainer>
                <FlexContainer
                    ref={hoverRef as Ref<HTMLDivElement>}
                    css={{
                        position: 'absolute',
                        width: '207px',
                        height: '100%',
                        '&:hover': {
                            background: 'rgba(0,0,0,0.7)',
                        },
                    }}
                >
                    {!isLoading && !isScheduled(recording.status) && (
                        <StyledVideoTileImageContainer
                            locked={isLocked}
                            iconsSize={44}
                            onClick={handleVideoTileClick}
                        />
                    )}
                    {recording && <RecordingStatusBadge recording={recording} />}
                </FlexContainer>
            </GridContentContainer>
        </StyledRecordingListItem>
    );
};
