/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
import 'core-js/stable';
import { h, render, Fragment } from 'preact';
import { useEffect, useState, useCallback } from 'preact/hooks';
import Router from 'preact-router';
import { connect, Provider } from 'unistore/preact';
import { NotificationScreen, keyboardCodes } from '@exaring/ui';
import { oauth2, authRedirectUrl } from '@exaring/networking/oauth2';
import {
    initErrorLogging,
    rootPath,
    logSetUser,
    events,
    localStorageItem,
    remainingStorageInHoursAndMinutes,
} from '@exaring/utils';
import * as channelSortStore from './actions/channel-sort';
import {
    Store,
    epgStore,
    userStore,
    useUserStore,
    useUiStore,
    uiStore,
    recordingStore,
    notificationsStore,
} from './state/Store';

import init from './appInit';
import WelcomeScreen from './components/WelcomeScreen';
import constants, { FeatureFlags } from './constants';
import strings from './strings';
import playoutActions from './actions/playout';

import Notifications from './components/Notifications';
import Header from './components/Header';
import store from './store';
import { lastActiveChannel } from './actions/helper';

import { Routes, routeToEpg, routeToLive } from './routes';
import MediaPlayer from './components/MediaPlayer';

import { RecordingPage as RecordingPage2 } from './pages/Recording2Page';
import { EpgPage } from './pages/EpgPage';
import { WaiputhekRouter } from './pages/WaiputhekRouter';
import Redirect from './pages/Redirect';
import { ConnectedChannelSortPage } from './pages/ChannelSortPage';

import { WebClientGA, WebClientGAEvent } from './web-client-ga';

import AppBlockerScreen, {
    appBlockerType,
    appBlockerReverseType,
    appBlockerCheck,
    dunningLevelCheck,
    userConfirmationCheck,
} from './components/AppBlockerScreen';

import Buildinfo from './components/Buildinfo';
import { errorHandler } from './errorHandler';
import { didNotAsk, didSucceed } from './state/utils/RemoteData';
import { getCurrentScreen } from './helper';
import { trackWithPlayerContext } from './tracking';
import { initialisePushNotifications } from '../services/pushNotifications';

const mapStateToProps = (state) => {
    const { player, playout } = state;
    return {
        playerIsActive: !playout.onHold,
        lastTimeshift: playout.lastTimeshift,
        playerIsReady: player.isLoaded && !player.isBusy,
        activeProgram: playout.activeProgram,
    };
};

const verifyChannelIdEpgV2 = (channelId) => {
    const { getStationById } = epgStore();
    const playoutChannelId = channelId && getStationById(channelId) && channelId; // fallbacks for channelId

    if (playoutChannelId) {
        // given channel id is valid no url rewrite
        return playoutChannelId;
    }

    return undefined;
};

const fallbackChannelIdEpgV2 = () => {
    const lastChannelId = lastActiveChannel();
    const {
        getStationById,
        epgStations: { value: epgChannels },
    } = epgStore();

    return (
        (lastChannelId && getStationById(lastChannelId) && lastChannelId) || // last seen channelId rank 2
        // choose first channel in channellist, as absolute fallback
        epgChannels[0]?.uuid
    );
};

// eslint-disable-next-line no-console
console.info(`%c${constants.RELEASE}__${constants.GIT_HASH}`, 'color: green');

if (FeatureFlags.enablePushNofications) {
    initialisePushNotifications(constants);
}

const App = (props) => {
    const [blockerScreen, setBlockerScreen] = useState(
        dunningLevelCheck() ||
            appBlockerCheck(window.navigator.userAgent) ||
            userConfirmationCheck(),
    );
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const { showBuildInfo, setShowBuildInfo } = useUiStore();

    const { firstName, lastName, fullName, userHandle, emailAddress } = useUserStore();

    const { playerIsActive, toggleOSD, initUnmuteIfNeeded } = props;

    const onKeyDown = useCallback(
        (e) => {
            if (e.ctrlKey && e.code === keyboardCodes.i) {
                setShowBuildInfo(!showBuildInfo);
            }
        },
        [setShowBuildInfo, showBuildInfo],
    );

    const hideBuildInfo = useCallback(() => {
        setShowBuildInfo(false);
    }, [setShowBuildInfo]);

    const hideWelcomeScreen = useCallback(
        (e) => {
            events.pauseEvent(e);

            localStorageItem('hasSeenWelcomeScreen', true);

            forceUpdate();
            toggleOSD(true);
            initUnmuteIfNeeded();
        },
        [forceUpdate, initUnmuteIfNeeded, toggleOSD],
    );

    useEffect(() => {
        init(); // wait for component mounting / store init and routing to be finished

        const setup = async () => {
            if (blockerScreen !== appBlockerType.noBlocker) {
                WebClientGA().trackEvent({
                    eventName: appBlockerReverseType[blockerScreen],
                    userHandle,
                });
            }

            document.addEventListener('keydown', onKeyDown, false);
        };

        setup();

        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, []);

    const trackScreenView = () => {
        return trackWithPlayerContext(
            WebClientGAEvent.ScreenView,
            store.getState(),
            undefined,
            getCurrentScreen(),
            { pause: !playerIsActive },
        );
    };

    const onRouteChange = useCallback(
        async (e) => {
            // WARNING: don't upgrade preact-router, see https://exaring.atlassian.net/browse/TVFUS-38042
            const { initVODPlayout, initPlayoutWithRecording, initLivePlayoutWithChannel, unload } =
                props;
            const matches = e.current?.props?.matches || {};
            const { setPreviousRoute } = uiStore();
            setPreviousRoute(e.previous);

            const {
                epgStations: { state: epg2State },
                fetchEpgStations,
            } = epgStore();

            // stations need to be there to verify channel id is valid
            if (epg2State === 'NotAsked' || epg2State === 'Loading') {
                await fetchEpgStations();
            }

            switch (rootPath(window.location.pathname)) {
                case Routes.RECORDING_PLAYOUT_PAGE: {
                    const { recordingId } = matches;

                    if (recordingId) {
                        await initPlayoutWithRecording(recordingId);
                        trackScreenView();
                    }
                    break;
                }
                case Routes.VOD_PLAYOUT_PAGE: {
                    const { programId, channelId, idfa } = matches;

                    if (programId && channelId) {
                        try {
                            await initVODPlayout(programId, channelId, idfa);
                            trackScreenView();
                        } catch (err) {
                            routeToEpg();
                        }
                    } else {
                        routeToEpg();
                    }

                    break;
                }
                case Routes.EPG_PAGE:
                case Routes.CHANNELCONFIG_PATH:
                case Routes.CHANNELSORT_NEW_PATH:
                case Routes.RECORDING_PAGE:
                case Routes.WAIPUTHEK_PAGE:
                case Routes.WAIPUTHEK_DETAILS_PAGE: {
                    unload();
                    break;
                }
                default:
                case Routes.LIVE_TV_PAGE: {
                    if (
                        !localStorageItem('user_storage_optout') &&
                        didNotAsk(recordingStore().recordingsSummary)
                    ) {
                        await recordingStore().initRecordingsSummary();
                        const { recordingsSummary } = recordingStore();
                        if (didSucceed(recordingsSummary)) {
                            const { maxStorage, usedStorage } = recordingsSummary.value;

                            const hasLowStorage =
                                !!maxStorage &&
                                !!usedStorage &&
                                maxStorage - usedStorage < constants.LOW_RECORDING_STORAGE_BOUNDARY;

                            if (hasLowStorage) {
                                notificationsStore().createRecordingStorageHint(
                                    remainingStorageInHoursAndMinutes(maxStorage, usedStorage),
                                );
                            }
                        }
                    }

                    let channelId = `${matches.channelId}`.toLowerCase();

                    channelId = verifyChannelIdEpgV2(channelId);

                    if (!channelId) {
                        routeToLive(fallbackChannelIdEpgV2());
                        break;
                    }

                    await initLivePlayoutWithChannel(channelId);
                    trackScreenView();
                    break;
                }
            }

            return undefined;
        },
        [props],
    );

    const user = { firstName, lastName, fullName, emailAddress };

    if (blockerScreen !== appBlockerType.noBlocker) {
        return (
            <AppBlockerScreen
                type={blockerScreen}
                user={user}
                brandName={strings.brandName}
                cancelBlocker={() => {
                    setBlockerScreen(appBlockerType.noBlocker);
                }}
            />
        );
    }

    // WARNING: do never replace this wrapping div with a fragment as this is causing whole vnode tree replacements during boot caused by setStates from unistore. Further investigation needed.
    return (
        <div>
            <Notifications />
            <MediaPlayer key="MediaPlayer" />
            <Router onChange={onRouteChange}>
                <EpgPage path={Routes.EPG_PATH} />
                <RecordingPage2 path={Routes.RECORDING_PATH} />
                {FeatureFlags.enableWaiputhek && <WaiputhekRouter path={Routes.WAIPUTHEK_PATH} />}
                <Redirect path={Routes.CHANNELSORT_PATH} to={Routes.CHANNELCONFIG_PATH} />
                <ConnectedChannelSortPage path={Routes.CHANNELCONFIG_PATH} />
                <div path={Routes.LIVE_TV_PATH} />
                <div path={Routes.RECORDING_PLAYOUT_PATH} />
                <div path={Routes.VOD_PLAYOUT_PATH} />
            </Router>
            {playerIsActive && !localStorageItem('hasSeenWelcomeScreen') && (
                <WelcomeScreen
                    fullName={fullName}
                    brandName={strings.brandName}
                    onClickAction={hideWelcomeScreen}
                />
            )}
            {showBuildInfo && <Buildinfo hideBuildInfo={hideBuildInfo} />}
        </div>
    );
};

const combinedActions = () => {
    return {
        ...playoutActions(store),
        ...channelSortStore.actions(store),
    };
};

const AppConnected = connect(mapStateToProps, combinedActions)(App);

const WebClient = () => {
    return (
        <Provider store={store}>
            {/* override redirect to auth on silent login error as it doesn't work due to a race condition */}
            <AppConnected />
        </Provider>
    );
};

const type = appBlockerCheck(window.navigator.userAgent);
const mount = document.querySelector('#spa-mount');

if (
    type &&
    !(type === appBlockerType.softBlockerBrowser) &&
    !(type === appBlockerType.softBlockerVersion)
) {
    // show all hard blocker immediately, soft block require login

    WebClientGA(); // init tracking

    render(
        <Provider store={store}>
            <AppBlockerScreen type={type} brandName={strings.brandName} />
        </Provider>,
        mount,
    );
} else {
    initErrorLogging({
        url: constants.SENTRY_URL,
        env: constants.ENV,
        release: constants.RELEASE,
        tenant: constants.TENANT,
        onBeforeSend: errorHandler,
    });

    oauth2({
        clientId: constants.CLIENT_ID,
        onSuccess: async (data) => {
            const { access_token: accessToken, refresh_token: refreshToken } = data;

            userStore().setStateWithJwt(accessToken, refreshToken);

            const {
                userHandle,
                isTrackingForbidden: isUserTrackingForbidden,
                setDeviceCapabilities,
            } = userStore();

            if (isUserTrackingForbidden) {
                WebClientGA().disable();
            } else {
                logSetUser(userHandle);
                WebClientGA().trackEvent({
                    eventName: 'data_privacy',
                    eventDescription: WebClientGAEvent.TrackingAuthorized,
                    screenName: 'data_privacy_management',
                    userHandle,
                });
                WebClientGA(); // init tracking
            }

            // shim userhandle to epg2 store until we migrate user store to zustand
            const { epg: epg2Store } = Store();
            epg2Store.setUserHandle(userHandle);

            WebClientGA().trackEvent({
                eventName: WebClientGAEvent.Login,
                userHandle,
            });

            setDeviceCapabilities(constants.RELEASE);

            render(<WebClient />, mount);
        },
        onError: () => {
            const ErrorPage = (
                <Provider store={store}>
                    <Fragment>
                        <Header hideInternalLinks />
                        <NotificationScreen
                            type="connection"
                            title="Es ist ein Authentifizierungsproblem aufgetreten"
                            content="Bitte versuchen Sie es erneut und wenden Sie sich gegebenenfalls an unseren Kundenservice."
                            buttons={[
                                {
                                    label: 'Erneut versuchen',
                                    action: () => window.location.reload(),
                                },
                                {
                                    label: 'Hilfe',
                                    action: () => {
                                        window.location.href = constants.HELPDESK_URL;
                                    },
                                },
                            ]}
                        />
                    </Fragment>
                </Provider>
            );

            render(ErrorPage, mount);
        },
        onUnauthorized: async () => {
            const redirectUrl = await authRedirectUrl(
                constants.AUTH_REDIRECT_URL,
                constants.CLIENT_ID,
            );
            window.location.replace(redirectUrl);
        },
    });
}
