import { useEffect } from 'preact/hooks';

/**
 * Hook that triggers a callback when a click event is detected outside of the ref element.
 * If a ref contains an array of elements, the callback will be triggered if the click event is outside of all elements.
 * @param ref
 * @param onClickAwayHandler
 */
export const useClickAway = (
    ref: any,
    onClickAwayHandler: (event: MouseEvent | TouchEvent) => void,
) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            const currentRef = ref.current;
            if (Array.isArray(currentRef)) {
                if (!currentRef.some((r: any) => r?.contains(event.target))) {
                    onClickAwayHandler(event);
                }
            } else if (currentRef && !currentRef?.contains(event.target)) {
                onClickAwayHandler(event);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, onClickAwayHandler]);
};
