import '@exaring/assets/styles/OnAirOutlineThree.css';
import '@exaring/assets/styles/OnAirBold.css';

import { styled } from '../../theme';
import { Typography } from '../../Typography/Typography';

export const RecordingDiscoveryNumber = styled(Typography, {
    width: '45px',
    fontWeight: '$5',
    lineHeight: '62px',
    fontSize: '62px',
    textAlign: 'center',
    paddingRight: '5px',
    letterSpacing: '-4px',
    fontFamily: 'OnAirOutlineThree, OnAirBold, Sans Serif',
});
