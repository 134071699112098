import { h, FunctionComponent } from 'preact';
import { ArrowBackButton, CloseButton } from '../IconButton';
import { styled } from '../theme';
import { LayoutType } from './types';

const StyledWrapper = styled('div', {
    backgroundColor: 'rgba(9, 7, 20, 0.9)',
    backdropFilter: 'blur(6px)',
    zIndex: '3',
    height: '44px',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    position: 'absolute',
    top: '-32px',
    right: '0',

    variants: {
        layout: {
            modal: {
                position: 'sticky',
                justifyContent: 'space-between',
                top: '0px',
            },
            player: {
                top: '80px',
                right: '14px',
                position: 'fixed',
                backgroundColor: 'transparent',
            },
            mediathek: {
                top: '64px',
                right: '13px',
                position: 'fixed',
                minHeight: '54px',
                alignItems: 'end',
            },
            microSite: {},
            epg: {
                top: '64px',
                alignItems: 'end',
                minHeight: '44px',
                right: '13px',
                position: 'fixed',
            },
        },
    },
});

export type CloseButtonProps = {
    onClose: () => void;
    onClickBack: () => void;
    layout?: LayoutType;
    isEpisodeView?: boolean;
};

export const CloseAndBackButtons: FunctionComponent<CloseButtonProps> = ({
    layout,
    onClickBack,
    onClose,
    isEpisodeView,
}) => (
    <StyledWrapper layout={layout}>
        {isEpisodeView ? <ArrowBackButton iconSize={44} onClick={onClickBack} /> : <div />}
        <CloseButton onClick={onClose} />
    </StyledWrapper>
);
