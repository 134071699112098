/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */
/*
eslint-disable import/no-unused-modules
*/
const strings = {
    pageTitle: 'O₂ TV powered by waipu.tv - Fernsehen wie noch nie.',
    brandName: 'O₂ TV',
    euUpgradeNotAllowedText:
        'Mit Ihrer aktuellen TV-Option ist eine Nutzung im EU-Ausland nicht möglich.',
    upsellingTitleRecordings: 'In Ihrer TV-Option ist die Aufnahmefunktion nicht enthalten.',
    upsellingContentRecordings:
        'Um Aufnahmen programmieren und abspielen zu können, buchen Sie bitte die O₂ TV-Option S, M, oder L.',
    upsellingTitle: 'Jetzt weitere exklusive Inhalte freischalten!',
    upsellingContent:
        'In unseren Paketen O₂ TV M und O₂ TV L stehen Ihnen jederzeit alle Filme und Serien der Mediathek zur Verfügung.',
    upsellingButtonLabel: '1 Monat kostenlos testen',
    waiputhekLabel: 'Mediathek',
} as const;

export default strings;
