/*
 * Copyright (C) Exaring AG - All Rights Reserved
 */

import { h, FunctionComponent } from 'preact';
import { styled, CSS } from '../theme';

export type TypographyElements =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2';

export const defaultVariantMapping: Record<TypographyElements, string> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
} as const;

export const hyperLinkStyles: CSS = {
    color: '$blue',
    textDecoration: 'none',
};
export const StyledLink = styled('a', hyperLinkStyles);

const hyperLinkStyling: CSS = {
    '> a': hyperLinkStyles,
};

const body1Styling: CSS = {
    fontFamily: '$primary',
    fontSize: '$2',
    fontWeight: '$2',
    lineHeight: '1.5em', // root style of web-client/microsite LESS
    margin: '0 0 $2',
    ...hyperLinkStyling,
};
const body2Styling: CSS = {
    fontFamily: '$primary',
    fontSize: '$2',
    lineHeight: '1.5em', // https://app.zeplin.io/project/56a6142e4a958f782afcee29/screen/621e1327dfa45f594081457d
    fontWeight: '$1',
    margin: '0 0 $2',
    ...hyperLinkStyling,
};
const headlineBaseStyles: CSS = {
    margin: '0 0 $2 0',
    fontFamily: '$primary',
    lineHeight: '1.7em',
    fontWeight: '$2',
    fontSize: '$4',
};

const h1Styling: CSS = headlineBaseStyles;

const h2Styling: CSS = {
    ...headlineBaseStyles,
    fontSize: '$3',
};

const h3Styling: CSS = {
    ...headlineBaseStyles,
    fontWeight: '$3',
};
const h6Styling: CSS = {
    ...headlineBaseStyles,
    fontSize: '$1',
};

const nowrapStyles: CSS = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

const centerStyles: CSS = {
    textAlign: 'center',
};

const nogutterStyles: CSS = {
    margin: 0,
};

const inlineStyles: CSS = {
    display: 'inline',
};

export const Typography: FunctionComponent<{
    id?: string;
    variant?: TypographyElements;
    css?: CSS;
    centered?: boolean;
    inline?: boolean;
    nowrap?: boolean;
    nogutter?: boolean;
    className?: string;
    /**
     * NOTICE the correct type would be As from node_modules/@stitches/react/types/styled-component.d.ts
     * WORKAROUND for bugged `as` prop: https://github.com/stitchesjs/stitches/issues/979
     * The value `div` of `<StyledTypography as='div' />` is not passed on to
     * the `Typography` component, but is intercepted directly by stitches, if
     * you created a styled version of `Typography` like `const
     * StyledTypography = styled(Typography, {...})`. What comes out is not a
     * styled `Typography` but a styled `div`. Therefore we can't use `as` here
     * and use `tagName` as a workaround.
     */
    tagName?: any;
    testId?: string;
}> = ({
    id,
    children,
    variant = 'body1',
    inline = false,
    centered = false,
    nowrap = false,
    nogutter = false,
    css,
    className,
    tagName,
    testId,
}) => {
    const DefaultStyle = body1Styling;
    const Variants: Record<TypographyElements, CSS> = {
        h1: h1Styling,
        h2: h2Styling,
        h3: h3Styling,
        h4: DefaultStyle, // not part of any design yet
        h5: DefaultStyle, // not part of any design yet
        h6: DefaultStyle, // not part of any design yet
        subtitle1: h6Styling,
        subtitle2: h6Styling,
        body2: body2Styling,
        body1: body1Styling,
    };
    const variantStyles = Variants[variant] ?? DefaultStyle;

    const ComposedComponent = styled(tagName || defaultVariantMapping[variant], {
        ...variantStyles,
        ...(nowrap ? nowrapStyles : {}),
        ...(nogutter ? nogutterStyles : {}),
        ...(centered ? centerStyles : {}),
        ...(inline ? inlineStyles : {}),
    });
    return (
        <ComposedComponent id={id} data-testid={testId} className={className} css={css}>
            {children}
        </ComposedComponent>
    );
};
